<template>
  <ValidationObserver ref="bankAccountForm" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">

      <!--   Select Master & Agent   -->
      <template v-if="!isAgent">
        <ValidationProvider
          v-if="isOwner"
          v-slot="{ errors }"
          :name="$t('bank_accounts.select_master')"
          label-for="select_master"
          rules="required"
      >
        <b-form-group
            :label="$t('bank_accounts.select_master')"
            :state="errors[0] ? false : null"
            label-cols-lg="3"
            label-cols-sm="4"
        >
          <b-select v-model="selectedMasterId">
            <b-select-option disabled value="">กรุณาเลือก Master</b-select-option>
            <b-select-option
                v-for="item in masters"
                :key="item.id"
                :value="item.id"
            >{{ item.name }}</b-select-option
            >
          </b-select>
        </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          :name="$t('bank_accounts.select_agent')"
          label-for="select_agent"
          rules="required"
        >
          <b-form-group
            :label="$t('bank_accounts.select_agent')"
            label-cols-lg="3"
            label-cols-sm="4"
            label-for="agent"
          >
            <b-form-select
              id="select_agent"
              v-model="bankAccountForm.agentId"
              :state="errors[0] ? false : null"
            >
              <template #first>
                <b-form-select-option disabled value="">
                  {{ `-- ${$t('fields.click_select')} --` }}
                </b-form-select-option>
              </template>
              <b-form-select-option
                v-for="item in agents"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </ValidationProvider>
        <hr />
      </template>

      <template v-if="currentMenu === 'information' || isFormCreate">
        <AccountForm
            :bank-account-form="bankAccountForm"
            @update="updateFormData"
        />
        <hr />
        <SettingForm
            :bank-account-form="bankAccountForm"
            @update="updateFormData"
        />
      </template>
      <template v-else-if="currentMenu === 'autoTransfer' && !isFormCreate">
        <AutoTransferForm
            :bank-account-form="bankAccountForm"
            @update="updateFormData"
        />
      </template>
      <template v-else-if="currentMenu === 'autoBanking' && !isFormCreate">
        <AutoBankingForm
            :bank-account-form="bankAccountForm"
            @update="updateFormData"
          />
      </template>

      <div class="text-right">
        <b-button class="mr-2" variant="light" @click="onCancel">
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button type="submit" variant="primary">
          {{ $t('buttons.save') }}
        </b-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  components: {
    AccountForm: () => import('./account-form'),
    SettingForm: () => import('./setting-form'),
    AutoTransferForm: () => import('./auto-transfer-form'),
    AutoBankingForm: () => import('./auto-banking-form'),
  },
  props: {
    isFormCreate: {
      type: Boolean,
      default: () => true,
    },
    currentMenu: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedMasterId: null,
      selectedAgentId: null,
      bankAccountForm: {
        bankCode: '',
        bankAccountNumber: '',
        bankAccountName: '',
        minTotalDeposit: 0,
        minBalance: 0,
        maxBalance: 0,
        limit: 0,
        isEnable: false,
        bankUseType: 0,
        agentId: null,
        displayFrom: '',
        displayTo: '',
        multiplierFee: 0,
        allowBankCodeList: [],
        maxHoldAmount: 0
      },
      isRequiredOtp: false,
    }
  },
  computed: {
    ...mapGetters(['isAgent', 'isMaster', 'isOwner', 'userBankAccountDetail']),
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    masters() {
      return this.userInfo.masters || []
    },
    agents() {
      if (this.isOwner) {
        return (
          this.userInfo.masters.find(
            (master) => master.id === this.selectedMasterId
          )?.children || []
        )
      } else if (this.isMaster) {
        return this.userInfo.masters[0].children || []
      } else if (this.isAgent) {
        return this.userInfo.masters[0].children || []
      }
      return []
    },
    isDisabledSelectAgent() {
      if (this.isOwner && !this.selectedMasterId) {
        return true
      }
      return false
    }
  },
  async created() {
    this.setData()
  },
  methods: {
    ...mapActions(['clearBankAccountForm', 'updateBankAccount', 'addBankAccount']),
    setData() {
      if (!this.isFormCreate && this.userBankAccountDetail?.id) {
        const data = JSON.parse(JSON.stringify(this.userBankAccountDetail))
        this.bankAccountForm = {
          ...data,
          minBalance: +data.minBalance,
          maxBalance: +data.maxBalance,
          agentId: data?.agent?.id || null,
        }
        this.bankAccountForm.isEnableTransferMinBalance = !!this.bankAccountForm.minBalance
        this.bankAccountForm.isEnableTransferMaxBalance = !!this.bankAccountForm.maxBalance
        this.selectedAgentId = data?.agent?.id
        this.selectedMasterId = data?.agent?.parentId
      }
    },
    updateFormData(val) {
      this.bankAccountForm = {
        ...this.bankAccountForm,
        ...val
      }
    },
    onSubmit() {
      const { isSetDisplayTime, isEnableTransferMinBalance, isEnableTransferMaxBalance, isEnableForMinDeposit } = this.bankAccountForm
      let data = {
        ...this.bankAccountForm,
        multiplierFee: +this.bankAccountForm.multiplierFee,
        displayFrom: isSetDisplayTime ? this.bankAccountForm.displayFrom.substring(0, 5) : null,
        displayTo: isSetDisplayTime ? this.bankAccountForm.displayTo.substring(0, 5) : null,
        minTotalDeposit: isEnableForMinDeposit ? this.bankAccountForm.minTotalDeposit : 0,
        minBalance: isEnableTransferMinBalance || isEnableTransferMinBalance === undefined ? this.bankAccountForm.minBalance : 0,
        maxBalance: isEnableTransferMaxBalance || isEnableTransferMaxBalance === undefined ? this.bankAccountForm.maxBalance : 0,
        maxHoldAmount: +this.bankAccountForm.maxHoldAmount
      }
      if (this.userBankAccountDetail?.id) {
        const { bankAccountNumber, bankCode } = this.bankAccountForm
        if(bankAccountNumber !== this.userBankAccountDetail.bankAccountNumber || bankCode !== this.userBankAccountDetail.bankCode) {
          this
              .$bvModal
              .msgBoxConfirm('หากแก้ไขเลขบัญชีหรือธนาคาร จะต้องลงทะเบียนระบบออโต้ใหม่ทั้งหมด', {
                title: 'กรุณาอ่านก่อนแก้ไขข้อมูล',
                okTitle: 'ตกลง',
                cancelTitle: 'ยกเลิก'
              })
              .then((result) => {
                if(result) {
                  this.updateBankAccount({ id: this.userBankAccountDetail.id, data })
                }
              })
        } else {
          this.updateBankAccount({ id: this.userBankAccountDetail.id, data })
        }
      } else {
        this.addBankAccount(data)
      }
    },
    onCancel() {
      this.clearBankAccountForm()
      this.$router.push('/bank-accounts/list')
    }
  },
}
</script>
